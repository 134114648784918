<template>
  <div
    class="misc-wrapper"
    style="background:white"
  >
    <b-link class="brand-logo">
      <b-img
        :src="require('@/assets/images/logo/logo.svg')"
        alt="Surfe logo"
        style="max-width:150px"
      />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Enrichment Hub 🚀
        </h2>

        <b-form
          inline
          class="row justify-content-center m-0 mb-4"
          @submit.prevent
        >
          <b-button
            :href="oauthLink"
            class="my-1 mt-2 btn-sm-block"
            type="submit"
            size="lg"
          >
            <b-img
              :src="require('@/assets/images/logo-google.png')"
              class="w-75"
            />
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BLink, BForm, BButton, BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BLink,
    BForm,
    BButton,
    BImg,
  },
  data() {
    return {
      oauthLink: `${process.env.VUE_APP_EH_API_URL}/sso/login`,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
